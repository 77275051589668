<template>
  <loader v-if="loading" />

  <section v-else id="classes" class="route">
    <!-- Heading -->
    <h1 class="title">
      <span>Classes</span>
      <hr class="divider divider--vertical" />
      <i class="fas fa-arrow-right grey-light--text"></i>
      <hr class="divider divider--vertical" />
      <span class="accent--text">{{ routeName }}</span>
    </h1>

    <!-- Online Classes warning -->
    <blockquote
      class="online-class-warning route-content"
      v-if="showOnlineClassWarning"
    >
      <i class="fas fa-exclamation-triangle accent--text"></i>
      &nbsp;
      <strong class="error--text">
        These are Live Stream Virtual Classes!
      </strong>
      If you would like to attend an <strong>In-Studio Class</strong> in Austin,
      Texas,
      <a
        href="https://www.myvinyasapractice.com/studio/schedule/"
        target="_blank"
        rel="noopener noreferrer"
      >
        go here instead.
      </a>
    </blockquote>

    <hr class="divider divider--tiny" />

    <!-- Registrations -->
    <registrations-list
      v-if="$route.name === 'Classes'"
      class="route-content"
      list-type="Online Class"
    />

    <!-- Active Route -->
    <router-view></router-view>
  </section>
</template>

<script>
/* eslint-disable no-undef */
import { routeName } from "../models/permissions";
import Loader from "../components/Loader.vue";
import RegistrationsMixin from "../components/mixins/registrations.mixin";
import SubrouteManagerMixin from "../components/mixins/subroute-manager.mixin";
import RegistrationsList from "../components/RegistrationsList.vue";

export default {
  name: "Classes",

  components: { Loader, RegistrationsList },

  mixins: [RegistrationsMixin, SubrouteManagerMixin],

  data: () => ({ classes: [], loading: true }),

  computed: {
    routeName() {
      return routeName(this.$route.name) || "Home";
    },

    showOnlineClassWarning() {
      const hiddenRoutes = ["CreateClass", "EditClass", "ClassSettings"];
      return !hiddenRoutes.includes(this.$route.name);
    },

    subroutes() {
      return [
        {
          name: "ListClasses",
          icon: "fas fa-presentation grey-light--text",
          condition: true
        },
        {
          name: "Classes",
          icon: "fas fa-calendar-check grey-light--text",
          condition: true
        },
        {
          name: "CreateClass",
          icon: "fas fa-plus-circle success--text",
          condition: true
        },
        {
          name: "ClassSettings",
          icon: "fas fa-cog grey-light--text",
          condition: this.isSuperAdminUser
        }
      ];
    }
  },

  mounted() {
    document.title = `Classes • ${APP_ORG} Admin`;
    this.broadcastSubroutes();
    this.loading = false;
  }
};
</script>

<style lang="scss" scoped>
.online-class-warning {
  @include slide-in-right;
  border-color: $grey-light;
  font-size: smaller;
  margin: 0 auto;
}
</style>
